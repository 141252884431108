*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f9f9f9;
}

*::-webkit-scrollbar-thumb {
  background-color: #014758;
  border-radius: 5px;
}

* {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.slick-slide div {
  outline: none;
}
