.loader-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  text-align: center;
  justify-content: center;
  align-items: center
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
}



.cs-loader {
  position: absolute;
  top: 60px;
  left: 0;
  height: 100%;
  width: 100%;
}

.cs-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: 100%;
  color: #FFF;
  padding: 0 100px;
  text-align: center;
}

.cs-loader-inner label {
  font-size: 20px;
  opacity: 0;
  color: #067e7a;
  display: inline-block;
}

@keyframes lol {
  0% {
      opacity: 0;
      transform: translateX(-300px);
  }

  33% {
      opacity: 1;
      transform: translateX(0px);
  }

  66% {
      opacity: 1;
      transform: translateX(0px);
  }

  100% {
      opacity: 0;
      transform: translateX(300px);
  }
}

@-webkit-keyframes lol {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-300px);
  }

  33% {
      opacity: 1;
      -webkit-transform: translateX(0px);
  }

  66% {
      opacity: 1;
      -webkit-transform: translateX(0px);
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(300px);
  }
}

.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
}